import { Timestamp } from 'firebase/firestore';
import { z } from 'zod';

const AsaTransaction = z.object({
  amount: z.number(),
  currency: z.string(),
  description: z.string(),
  isIgnoredTransaction: z.boolean().nullable(), // @ASA - what?
  isrecurring: z.boolean().nullable(), // @ASA - what?
  merchantname: z.string(),
  transactioncategory: z.string(),
  transactioncategorydescription: z.string(),
  transactioncategoryid: z.number(),
  transactiondate: z.string(),
  transactionid: z.string(),
  transactionmemo: z.string(),
  transactionstatus: z.enum(['Posted', 'Pending'] as const),
  transactiontype: z.enum(['Credit', 'Debit'] as const),
  transactiontypecode: z.enum(['C', 'D'] as const).nullable(), //@ASA - what?
  transactiontypeid: z.union([z.literal(1), z.literal(2)]),
});

const AsaTransactionsAccount = z.object({
  account_id: z.string(),
  account_typeid: z.number(),
  accountname: z.string(),
  asaficode: z.number(),
  balances: z.object({
    current: z.number(),
    available: z.number(),
    iso_currency_code: z.string(),
  }),
  pagenumber: z.number(),
  pagesize: z.number(),
  total_transactions: z.number(),
  transactions: AsaTransaction.array(),
  transactionsLastSynced: z.string().nullable(),
});

const AsaResponseBase = z.object({
  message: z.string(),
  reference: z.object({ timeStamp: z.string(), requestId: z.string() }).array(),
  status: z.number(),
  version: z.string(),
});

export const AsaTransactionsGetResponse = AsaResponseBase.extend({
  data: z.object({ account: AsaTransactionsAccount }).array(),
});

const AsaAccountType = z.enum([
  '401k',
  'ATV',
  'Auto',
  'Bank',
  'Boat',
  'Bond',
  'Brokerage',
  'Cash',
  'Checking',
  'Commercial Loan',
  'Commercial',
  'Credit Card',
  'Credit Other',
  'Credit',
  'Crypto',
  'HELOC',
  'IRA',
  'Insurance',
  'Insurance',
  'Investments Other',
  'Jewelry Loan',
  'Jewelry',
  'Land',
  'Line of Credit',
  'Loan Other',
  'Loan',
  'Motorcycle',
  'Personal Loan',
  'Plane',
  'Precious Metal Loan',
  'Precious Metal',
  'Property',
  'RV',
  'Real Estate Loan',
  'Real Estate Other',
  'Real Estate',
  'Residential',
  'Roth',
  'Savings',
  'Stock',
  'Valuables',
  'Vehicle Loan',
  'Vehicle Other',
  'Vehicle',
] as const);

export type AsaAccountType = z.infer<typeof AsaAccountType>;
const AsaAccount = z.object({
  accountName: z.string().nullable(), //'Closed end';
  accountNumber: z.string(), // 'XXXXXXXXXXX0000';
  accountType: AsaAccountType,
  accountTypeDescription: z.string(), // 'Bank Savings';
  accountTypeId: z.number(), // 2;// @ASA - update account type ids
  asaConsumerCode: z.number(), //1860302133;
  asaFiAccountCode: z.number(), // 962386086;
  asaFintechCode: z.number(), //1950024027;
  availableBalance: z.number(),
  balance: z.number(), //14858.95;
  calculatedBalance: z.number(),
  currencyCode: z.string().nullable(), //null;
  dateOpened: z.string().nullable(), //'2018-02-19T00:00:00.0000000';
  description: z.string().nullable(), //'Charge Off - 2007 NISSA Loan';
});
export type AsaAccount = z.infer<typeof AsaAccount>;

export const AsaAccountsGetResponse = AsaResponseBase.extend({
  data: AsaAccount.array().nullable(),
});

export type AsaTransactionsGetResponseType = z.infer<
  typeof AsaTransactionsGetResponse
>;

export type AsaTransactionsAccountType = z.infer<typeof AsaTransactionsAccount>;
export type AsaTransactionType = z.infer<typeof AsaTransaction>;

// fix
export type AsaWebhookDataDoc =
  | AsaWebhookConnectionStatusChange
  | AsaWebhookGetFiData
  | AsaWebhookHistoricalUpdate
  | AsaWebhookTestInitData
  | AsaWebhookDataAccessChange;

type BaseWebhook = {
  created_on: Timestamp;
  doc_id: string;
};

type AsaWebhookTestInitData = BaseWebhook & {
  // used for testing only
  EventName: 'OG_ASA_INITIAL_DATA';
  AsaConsumerCode: string;
  group_id: string;
};

export type AsaWebhookConnectionStatusChange = BaseWebhook & {
  ASAFintechCode: string;
  AsaConsumerCode: string;
  EventName: 'AccountConnectionStatusChange';
  Message: string;
  RequestId: string;
  TimeStamp: string;
  Data: {
    AccountLast4: string;
    AccountStatus: 'Connected' | 'RequireAttention';
    AsaFiAccountCode: number;
    AsaConsumerCode: number;
    AsaFiCode: number;
    AsaFintechCode: number;
  }[];
};

type AsaWebhookGetFiData = BaseWebhook & {
  ASAFintechCode: string; //'1950024027';
  AsaConsumerCode: string; // 1403197038;
  Data: { account: AsaTransactionsAccountType }[];
  EventName: 'GetFiData';
  Message: string; //'DataUpdate triggered. Event GetFiData for AsaConsumerCode 1403197038 AsaFintechCode 1950024027   Imported 1 new  transactions.';
  RequestId: null | string;
  TimeStamp: string; // '2024-03-26T15:01:28.6334483Z';
};

export type AsaWebhookDataAccessChange = BaseWebhook & {
  ASAFintechCode: string; //'1950024027';
  AsaConsumerCode: string; // 1403197038;
  Data: {
    AccountDetails: boolean;
    AllowTransfer: boolean;
    AsaFiAccountCode: number;
    AsaFiCode: number;
    AuthorizationStatus: number;
    EmailAddress: string;
    TransactionData: boolean;
    TransferThresholdAmount: number;
  }[];
  EventName: 'ConsumerDataAccessToFintechs';
  Message: string; //'"Consumer Account Authorized triggered. Event ConsumerDataAccessToFintechs for AsaConsumerCode 37880615 AsaFintechCode 1950024027 Fintech with AsaFintechCode: 1950024027 has been Authorized to Access Consumer Account: 1706826379 for Consumer with ASA Consumer Code : 37880615 Linked to Fi : 1950651022"';
  RequestId: null | string;
  TimeStamp: string; // '2024-03-26T15:01:28.6334483Z';
};

type AsaWebhookHistoricalUpdate = BaseWebhook & {
  AsaConsumerCode: string;
  EventName: 'OG_ASA_HISTORICAL_UPDATE';
};

type AsaResponseBase<T> = {
  data: T;
  message: string;
  reference: { timeStamp: string; requestId: string }[];
  status: number;
  version: string;
};

export type AsaAuthenticateResponse = AsaResponseBase<{
  field: 'UniqueLoginUrl';
  message: string; // this is the URL to redirect to login
}>;

export type AsaLoginResponse = AsaResponseBase<{
  appName: string;
  asaConsumerCode: string;
  asaFintechCode: string;
  email: string;
  errorCode: string;
  errorMessage: string;
  redirectURL: string;
  scope: string;
  status: string;
  token: string;
  tokenExpiryDate: string;
}>;

export type AsaRefreshTokenResponse = AsaResponseBase<{
  token: string;
  tokenExpiryDate: string; //'2024-05-17T21:35:25.847Z';
}>;
